import React, { useEffect, useRef, useState } from "react";
import styles from "./UserPrompts.module.css";
import { isNonEmptyArray } from "../../../../../../../common/utils";
import { translate } from "../../../../../../../common/providers";

import CustomTextField from "../../../../../../../common/components/text-fields/text-field/CustomTextField";
import AdditionalInfoBox from "../additional-info-box/AdditionalnfoBox";
import { useDispatch, useSelector } from "react-redux";
import { setQuestion } from "../../../../../slice/multi-project-search-slice";

const IS_ENABLED = false;

const UserPrompts = ({ additionalContext, onAdd, onRemove }) => {
  const question = useSelector(({ multiProjectSearch }) => multiProjectSearch.question);
  const [tempUserPromp, setTempUserPromp] = useState("");
  const shiftPressed = useRef();
  const dispatch = useDispatch();
  const handleAdd = () => {
    if (shiftPressed.current) {
      return;
    }
    onAdd(tempUserPromp);
    setTempUserPromp("");
  };
  useEffect(() => {
    setTempUserPromp(question);
  }, []);
  useEffect(() => {
    dispatch(setQuestion(tempUserPromp));
  }, [tempUserPromp]);
  useEffect(() => {
    if (!IS_ENABLED) {
      return;
    }
    const handleKeyDetect = (e) => {
      if (e.repeat) {
        return;
      }
      if (e.key === "Enter") {
        handleAdd();
      }
      if (e.key === "Shift") {
        shiftPressed.current = true;
      }
    };
    const handleKeyRelease = (e) => {
      if (e.key === "Shift") {
        shiftPressed.current = false;
      }
    };
    window.addEventListener("keydown", handleKeyDetect, true);
    window.addEventListener("keyup", handleKeyRelease, true);
    return () => {
      window.removeEventListener("keydown", handleKeyDetect, true);
      window.removeEventListener("keyup", handleKeyRelease, true);
    };
  }, [tempUserPromp]);
  const handleChangeTempContext = (e) => {
    const { value } = e.target;
    setTempUserPromp(value);
  };
  return (
    <>
      <CustomTextField
        fullWidth
        multiline
        className={styles.textfield}
        placeholder={translate("multi-projects-search.generate.add-item-to-prompt")}
        rows={3}
        size="sm"
        value={tempUserPromp}
        onChange={handleChangeTempContext}
      />
      <div className={styles.additional__helper}>
        {translate("multi-projects-search.generate.btn.improve-proposal")}
      </div>
      {IS_ENABLED && (
        <div className={styles.additionalContextContainer}>
          {isNonEmptyArray(additionalContext) &&
            additionalContext.map((ac) => <AdditionalInfoBox key={ac} value={ac} onRemove={onRemove} />)}
        </div>
      )}
    </>
  );
};

export default UserPrompts;
